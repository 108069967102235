import { Navigate, Route, Routes } from "react-router-dom";
import "./../App.css";
import Login from "../pages/Login";
import ReservationManagement from "./../pages/ReservationManagement";
import SalesManagement from "./../pages/SalesManagement";
import SalesManagementByDay from "./../pages/SalesManagement/byDay";
import SalesManagementByMonth from "./../pages/SalesManagement/byMonth";
import SalesManagementByTherapist from "./../pages/SalesManagement/byTherapist";
import CustomerManagement from "./../pages/CustomerManagement";
import DailyReportPage from "./../pages/DailyReport";
import StaffSchedulePage from "./../pages/StaffSchedule";
import TherapistManagement from "./../pages/TherapistManagement";
import TherapistShift from "./../pages/TherapistShift";
import CustomerMaster from "./../pages/CustomerMaster";
import StoreMaster from "./../pages/StoreMaster";
import TherapistMaster from "./../pages/TherapistMaster";
import ReferrerMaster from "./../pages/ReferrerMaster";
import UserMaster from "./../pages/UserMaster";
import AccountMasterCourse from "./../pages/AccountMasterCourse";
import AccountMasterAssign from "./../pages/AccountMasterAssign";
import AccountMasterOption from "./../pages/AccountMasterOption";
import AccountMasterPayment from "./../pages/AccountMasterPayment";
import AccountMasterExtend from "./../pages/AccountMasterExtend";
import Log from "./../pages/Log";
import Auth from "./authRoute";
import NotFound from "../pages/NotFound";
import ActivityLog from "../pages/ActivityLog";
import TherapistShiftView from "../pages/TherapistShiftView";
import AccountMasterEventImage from "../pages/AccountMasterEventImage";
import MonthlyReportPage from "../pages/MonthlyReport";
import BasicInformationPage from "../pages/BasicInformation";

function Root() {
  return (
    <div className="root-wrapper">
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<Auth />}>
          <Route path="/" element={<Navigate to="/staff_schedule" replace />} />

          <Route path="/reservemgr" element={<ReservationManagement />} />
          <Route path="/salesmgr" element={<SalesManagement />} />
          <Route path="/salesmgr_byday" element={<SalesManagementByDay />} />
          <Route
            path="/salesmgr_bymonth"
            element={<SalesManagementByMonth />}
          />
          <Route
            path="/salesmgr_bytherapist"
            element={<SalesManagementByTherapist />}
          />
          <Route path="/customermgr" element={<CustomerManagement />} />
          <Route path="/dailyreport" element={<DailyReportPage />} />
          <Route path="/monthlyreport" element={<MonthlyReportPage />} />
          <Route path="/basicinformation" element={<BasicInformationPage />} />
          <Route path="/staff_schedule" element={<StaffSchedulePage />} />

          <Route
            path="/therapistmanagement"
            element={<TherapistManagement />}
          />

          <Route
            path="/therapistshift-therapist-view/:id"
            element={<TherapistShiftView />}
          />

          <Route path="/therapistshift" element={<TherapistShift />} />

          <Route path="/customermaster" element={<CustomerMaster />} />
          <Route path="/storemaster" element={<StoreMaster />} />
          <Route path="/therapistmaster" element={<TherapistMaster />} />
          <Route path="/referrermaster" element={<ReferrerMaster />} />
          <Route path="/usermaster" element={<UserMaster />} />
          <Route
            path="/accountmaster_course"
            element={<AccountMasterCourse />}
          />
          <Route
            path="/accountmaster_nomination"
            element={<AccountMasterAssign />}
          />
          <Route
            path="/accountmaster_option"
            element={<AccountMasterOption />}
          />
          <Route
            path="/accountmaster_payment"
            element={<AccountMasterPayment />}
          />
          <Route
            path="/accountmaster_extend"
            element={<AccountMasterExtend />}
          />
          <Route
            path="/accountmaster_event_image"
            element={<AccountMasterEventImage />}
          />
          <Route path="/activity_log" element={<ActivityLog />} />

          <Route path="/log" element={<Log />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default Root;
