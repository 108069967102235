import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import DatePickerComponent from "../../components/DatePicker";
import { useQuery, useQueryClient } from "react-query";
import usePaginate from "../../hooks/usePaginate";
import TablePaginationLayout from "../../layouts/TablePagination";
import { STALE_TIME_DEFAULT } from "../../constants";
import {
  exportCsvMonthlyReport,
  getListMonthlyReport,
} from "../../api/dailyReport";
import { downloadCSV } from "../../utils";
import { iconDownload, iconRedDelete, iconWhiteDelete } from "../../assets";
import { deleteMultipleOrders } from "../../api/orders";

const styleTableHead = {
  backgroundColor: "#000",
  color: "#fff",
  minWidth: "100px",
  textAlign: "center",
  verticalAlign: "middle",
  whiteSpace: "pre",
  paddingLeft: "3px",
  paddingRight: "3px",
};

const styleTableBody = {
  ...styleTableHead,
  color: "#000",
  backgroundColor: "transparent",
};

const MonthlyReport = () => {
  const queryClient = useQueryClient();
  const [monthSelected, setMonthSelected] = useState(dayjs(new Date()));

  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [monthlyReportFields, setMonthlyReportFields] = useState([]);
  const [monthlyReportList, setMonthlyReportList] = useState([]);
  const { page, perPage, total, changePage, setTotal, changePerPage } =
    usePaginate(0, 10);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [orderSelectedDelete, setOrderSelectedDelete] = useState([]);

  const { data: dataMonthlyReport, refetch: refetchMonthlyReport } = useQuery(
    ["list_monthly_report", monthSelected.format("YYYY-MM"), page],
    () => {
      return getListMonthlyReport({
        date_month: monthSelected.format("YYYY-MM"),
        per_page: perPage,
        page: page + 1,
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  const handleExportCSV = useCallback(async () => {
    if (isLoadingExport) return;
    try {
      setIsLoadingExport(true);
      const response = await exportCsvMonthlyReport({
        date_month: monthSelected.format("YYYY-MM"),
      });
      if (response?.data?.data && response?.data?.columns) {
        downloadCSV(
          response.data.columns,
          response.data.data,
          "monthly_report" + monthSelected.format("YYYY-MM")
        );
      } else {
        alert("エクスポートに失敗しました。");
      }
      setIsLoadingExport(false);
    } catch (error) {
      alert("エクスポートに失敗しました。");
      setIsLoadingExport(false);
    }
  }, [monthSelected, isLoadingExport]);

  const handleSelectionDelete = useCallback(async () => {
    if (isLoadingDelete) return;
    if (orderSelectedDelete.length === 0) {
      alert("削除する予約を選択してください。");
      return;
    } else {
      // show dialog confirm delete
      if (window.confirm("選択した予約を削除しますか？")) {
        setIsLoadingDelete(true);
        const ids = orderSelectedDelete.map((e) => e[0]);
        const params = { ids: ids };
        try {
          const response = await deleteMultipleOrders(params);
          if (response.success) {
            queryClient.invalidateQueries("list_monthly_report");
            await refetchMonthlyReport();
            setOrderSelectedDelete([]);
            alert("削除しました。");
          } else {
            alert("削除に失敗しました。");
          }
        } catch (error) {
          alert("削除に失敗しました。");
        } finally {
          setIsLoadingDelete(false);
        }
      }
    }
  }, [orderSelectedDelete, isLoadingDelete, refetchMonthlyReport, queryClient]);

  const handleBulkDelete = useCallback(async () => {
    if (isLoadingDelete) return;
    // show dialog confirm delete all
    if (window.confirm("この日のすべての予約を削除しますか？")) {
      setIsLoadingDelete(true);
      const params = {
        start_date: monthSelected.startOf("month").format("YYYY-MM-DD"),
        end_date: monthSelected.endOf("month").format("YYYY-MM-DD"),
      };
      try {
        const response = await deleteMultipleOrders(params);
        if (response.success) {
          queryClient.invalidateQueries("list_monthly_report");
          await refetchMonthlyReport();
          alert("削除しました。");
        } else {
          alert("削除に失敗しました。");
        }
      } catch (error) {
        alert("削除に失敗しました。");
      } finally {
        setIsLoadingDelete(false);
      }
    }
  }, [isLoadingDelete, queryClient, refetchMonthlyReport, monthSelected]);

  useEffect(() => {
    if (dataMonthlyReport) {
      setMonthlyReportFields(dataMonthlyReport?.data?.columns || []);
      setMonthlyReportList(dataMonthlyReport?.data?.data || []);
      setTotal(dataMonthlyReport?.data?.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMonthlyReport]);

  return (
    <>
      <Container maxWidth="lg">
        <Typography
          sx={{
            fontSize: "28px",
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          月報
        </Typography>
      </Container>
      <Container
        // maxWidth="xl"
        sx={{
          padding: "0px !important",
        }}
      >
        <Box
          sx={{
            marginTop: "40px",
            paddingRight: "16px",
            paddingLeft: "16px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() =>
                  setMonthSelected((prev) => prev.subtract(1, "month"))
                }
              >
                前月
              </Button>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <FormControl>
                <DatePickerComponent
                  currentDate={monthSelected}
                  onDateChange={setMonthSelected}
                  views={["year", "month"]}
                  format="YYYY年MM月"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ flexGrow: 1 }}>
              <Button
                variant="outlined"
                onClick={() => setMonthSelected((prev) => prev.add(1, "month"))}
              >
                翌月
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end", gap: "16px" }}
            >
              <Button
                variant="contained"
                type="button"
                color="primary"
                startIcon={
                  <img src={iconDownload} alt="iconDownload" width="16" />
                }
                style={{ fontWeight: "600", backgroundColor: "#000" }}
                onClick={handleExportCSV}
                disabled={isLoadingExport}
              >
                CSVダウンロード
              </Button>
              <Button
                variant="contained"
                type="button"
                color="primary"
                startIcon={<img src={iconRedDelete} alt="delete" width="16" />}
                style={{
                  fontWeight: "600",
                  backgroundColor: "white",
                  color: "rgba(211, 47, 47, 1)",
                  border: "1px solid rgba(211, 47, 47, 1)",
                }}
                onClick={handleSelectionDelete}
              >
                選択削除
              </Button>
              <Button
                variant="contained"
                type="button"
                color="primary"
                startIcon={
                  <img src={iconWhiteDelete} alt="delete" width="16" />
                }
                style={{
                  fontWeight: "600",
                  backgroundColor: "rgba(211, 47, 47, 1)",
                  color: "white",
                  border: "1px solid rgba(211, 47, 47, 1)",
                }}
                onClick={handleBulkDelete}
              >
                一括削除
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ margin: "40px 0" }}>
          <Paper
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgba(0, 0, 0, 0.87)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              width: "100%",
              marginBottom: "16px",
              overflow: "auto",
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={styleTableHead}>　</TableCell>
                    {monthlyReportFields.map(
                      (field, index) =>
                        field !== "ID" && (
                          <TableCell key={index} sx={styleTableHead}>
                            {field}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {monthlyReportList.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell sx={styleTableBody}>
                        <Checkbox
                          style={{ padding: "0" }}
                          key={item[0]}
                          checked={orderSelectedDelete.some(
                            (e) => e[0] === item[0]
                          )}
                          onClick={() => {
                            if (
                              orderSelectedDelete.some((e) => e[0] === item[0])
                            ) {
                              setOrderSelectedDelete(
                                orderSelectedDelete.filter(
                                  (e) => e[0] !== item[0]
                                )
                              );
                            } else {
                              setOrderSelectedDelete([
                                ...orderSelectedDelete,
                                item,
                              ]);
                            }
                          }}
                        />
                      </TableCell>
                      {item.map((value, index) => index !== 0 && (
                        <TableCell key={index} sx={styleTableBody}>
                          {value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  {monthlyReportList.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={monthlyReportFields.length}
                        align="center"
                      >
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={perPage}
              total={total}
              page={page}
              rowsPerPageOptions={[10]}
              onPageChange={changePage}
              handleChangeRowsPerPage={changePerPage}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default MonthlyReport;
