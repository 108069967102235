import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import React from "react";
import DailyReport from "../../features/dailyReport/DailyReport";

function DailyReportPage() {
  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <DailyReport />
    </>
  );
}

export default DailyReportPage;
