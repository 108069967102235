import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import DatePickerRangeComponent from "../../components/DatePickerRange";
import styled from "./style.module.css";
import { useQuery } from "react-query";
import { getRevenueByArea } from "../../api/dailyReport";
import { STALE_TIME_DEFAULT } from "../../constants";
import { formatCurrency } from "../../utils";

const styleHead = {
  minWidth: "70px",
  border: "1px solid #ddd",
  textAlign: "center",
  verticalAlign: "middle",
  padding: "auto",
  paddingLeft: 0,
  paddingRight: 0,
};

const RevenueArea = () => {
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const { data: dataArea } = useQuery(
    ["list_revenue_area", startDate, endDate],
    () => {
      return getRevenueByArea({
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
        per_page: 300,
      });
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME_DEFAULT,
    }
  );

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <Container maxWidth="xl">
      <div className={`${styled.dateRangeWrapper}`}>
        <Typography
          sx={{
            fontSize: {
              md: 20,
              xs: 10,
            },
            letterSpacing: {
              md: "3px",
              xs: 0,
            },
            color: "#fff",
            textDecoration: "none",
          }}
        >
          エリア別売上と利益
        </Typography>
        <div className={`${styled.dateRangeContainer}`}>
          <DatePickerRangeComponent
            onDateChange={(start, end) => handleDateChange(start, end)}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>

      <Container
        // maxWidth="xl"
        sx={{
          padding: "0px !important",
        }}
      >
        <Box sx={{ marginTop: "20px" }}>
          <Paper
            sx={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgba(0, 0, 0, 0.87)",
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              width: "100%",
              marginBottom: "16px",
              overflow: "auto",
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {dataArea?.data.map((area, index) => (
                      <TableCell
                        key={index}
                        colSpan={2}
                        sx={{ ...styleHead, minWidth: "140px" }}
                      >
                        {area.area_name}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {dataArea?.data.map((area, index) => (
                      <React.Fragment key={index}>
                        <TableCell sx={styleHead}>売上</TableCell>
                        <TableCell sx={styleHead}>利益</TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {dataArea?.data.map((area, index) => (
                      <React.Fragment key={index}>
                        <TableCell sx={styleHead}>
                          {formatCurrency(area.total_price)}
                        </TableCell>
                        <TableCell sx={styleHead}>
                          {formatCurrency(area.total_profit)}
                        </TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                  {(!dataArea?.data || dataArea?.data.length === 0) && (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
    </Container>
  );
};

export default RevenueArea;
