import React, { useCallback, useEffect } from "react";
import PopupComponent from "../../layouts/Popup";
import { Button, Checkbox, Grid } from "@mui/material";
import { getListOption } from "../../api/option";
import { saveOptions } from "../../api/course";
import { useQuery } from "react-query";

const PopupCourseOption = ({
  course,
  isOpen,
  onClose,
  onSubmit = () => {},
}) => {
  const [options, setOptions] = React.useState([]);

  const { data: dataOption } = useQuery(["options_for_course"], () => {
    const params = {
      per_page: 1000,
    };
    return getListOption(params);
  });

  const onSave = useCallback(() => {
    onClose();
    onSubmit();
  }, [onClose, onSubmit]);

  const handleSave = useCallback(async () => {
    const selectedOptionIds = options
      .filter((option) => option.selected)
      .map((option) => option.id);

    const params = {
      option_ids: selectedOptionIds,
    };
    try {
      const response = await saveOptions(course.id, params);
      if (response && response.success) {
        onSave();
        alert("保存しました。");
      } else {
        alert("エラーが発生しました。");
        return false;
      }
    } catch (error) {
      alert("エラーが発生しました。");
      return false;
    }
  }, [onSave, course, options]);

  useEffect(() => {
    const optionList = dataOption?.data?.data;
    if (course && optionList?.length > 0) {
      const showOptions = [];
      optionList.forEach((element) => {
        element.selected = course?.options?.some(
          (option) => option.id === element.id
        );
        showOptions.push(element);
      });
      setOptions(showOptions);
    }
  }, [dataOption, course]);

  return (
    <PopupComponent open={isOpen} onClose={onClose} width={700}>
      <Grid
        container
        spacing={1}
        style={{ marginTop: 20, alignItems: "center" }}
      >
        {options.map((option, index) => (
          <Grid
            container
            key={option.id}
            spacing={1}
            style={{ marginTop: 20, alignItems: "center" }}
          >
            <Checkbox
              style={{
                padding: 0,
                width: 27,
                height: 27,
              }}
              checked={option.selected}
              onChange={(e) => {
                const newOptions = [...options];
                newOptions[index].selected = e.target.checked;
                setOptions(newOptions);
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                width: "calc(100% - 27px - 12px)",
                marginLeft: 12,
              }}
            >
              <span
                style={{
                  fontSize: "0.8rem",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                コース名 *
              </span>
              <span>{option.name}</span>
            </div>
          </Grid>
        ))}
      </Grid>
      <Button
        type="button"
        fullWidth
        variant="outlined"
        color="success"
        style={{ marginTop: 20 }}
        onClick={handleSave}
      >
        保存
      </Button>
    </PopupComponent>
  );
};

export default PopupCourseOption;
