import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import React from "react";
import MonthlyReport from "../../features/dailyReport/MonthlyReport";

function MonthlyReportPage() {

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      <MonthlyReport />
    </>
  );
}

export default MonthlyReportPage;
