import { sendDelete, sendGet, sendPost, sendPut } from "./axios";

export const getListCourse = (params) => sendGet("/courses", params);
export const createCourse = (params) => sendPost("/courses", params);

export const updateCourse = (id, params) =>
  sendPut(`/courses/${id}`, params);

export const deleteCourse = (id) => sendDelete(`/courses/${id}`);

export const upOrderNumber = (id) =>
  sendPost(`/courses/${id}/up-order-number`);

export const downOrderNumber = (id) =>
  sendPost(`/courses/${id}/down-order-number`);

export const upTopOrderNumber = (id) =>
  sendPost(`/courses/${id}/uptop-order-number`);

export const downBottomOrderNumber = (id) =>
  sendPost(`/courses/${id}/downbot-order-number`);

export const saveOptions = (id, params) =>
  sendPost(`/courses/${id}/options`, params);
