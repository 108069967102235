export const getCost = (list = [], id) => {
  try {
    const obj = list.find((item) => item.id === id);
    if (!obj) return 0;
    return Number(obj?.cost) || 0;
  } catch (error) {
    return 0;
  }
};

export const getDuration = (list = [], id, key) => {
  try {
    const obj = list.find((item) => item.id === id);
    if (!obj) return 0;
    return obj?.[key] || 0;
  } catch (error) { }
};

export const handleSetErrorToField = (objError, setError) => {
  for (let key in objError) {
    setError(key, {
      type: "manual",
      message: objError[key],
    });
  }
};

export const getCountOrderNominatation = (list, id) => {
  const nomination = list.find((item) => item.id === id);
  return nomination?.count_order || 0;
};

export const getValueBoolean = (value) => {
  return value ? "〇" : "×";
};

export const formatJapaneseDate = (date) => {
  const d = new Date(date);
  return `${d.getMonth() + 1}月${d.getDate()}日`;
};

export const formatCurrency = (value) => {
  const valueInt = parseInt(value, 10);

  if (isNaN(valueInt)) {
    return "0円";
  }

  return `${valueInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円`;
};

export const formatNumberWithUnit = (value, unit) => {
  const valueInt = parseInt(value, 10);

  if (isNaN(valueInt)) {
    return `0${unit}`;
  }

  return `${valueInt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit}`;
};

export const isNumeric = (value) => {
  return !isNaN(value) && !isNaN(parseFloat(value));
};

export const getByKey = (list = [], id, key) => {
  try {
    const obj = list.find((item) => item.id === id);
    if (!obj) return 0;
    return Number(obj?.[key]) || 0;
  } catch (error) {
    return 0;
  }
}

export const downloadCSV = (columns, data, fileName) => {
  if (!data || data.length === 0 || !columns || columns.length === 0) {
    return;
  }

  const escapeValue = (value) => {
    if (typeof value === "string" && (value.includes(",") || value.includes("\n") || value.includes('"'))) {
      return `"${value.replace(/"/g, '""')}"`; // Escape dấu ngoặc kép
    }
    return value;
  };

  const csv = [
    columns.map(escapeValue).join(","), // Header
    ...data.map((row) => row.map(escapeValue).join(",")) // Body
  ].join("\n");

  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.csv`;
  link.click();
  URL.revokeObjectURL(url);
}
