import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  createUser,
  deleteUser,
  getListUser,
  updateUser,
} from "../../api/user";
import { isNumeric } from "../../utils";
const roleOptions = [
  { id: 1, value: "管理者" },
  { id: 2, value: "一般利用者" },
];

const useUserMaster = () => {
  const [arrUsers, setArrUsers] = useState([]);
  const [activeSortColumn, setActiveSortColumn] = useState(0);
  const [sortColumnDirection, setSortColumnDirection] = useState("desc");
  const [sortedData, setSortedData] = useState([]);
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
    remarks: ''
  });

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [editUser, setEditUser] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
    remarks: ''
  });
  const [isPopupOpenDelete, setIsPopupOpenDelete] = useState(false);
  const [isPopupOpenUpdate, setIsPopupOpenUpdate] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [focusState, setFocusState] = useState({
    name: false,
    email: false,
    password: false,
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    remarks: ''
  });

  const [errorsUpdate, setErrorsUpdate] = useState({
    name: "",
    email: "",
    password: '',
    remarks: ''
  });

  const nameInputRef = useRef(null);

  const { data, refetch } = useQuery(
    ["users", page, rowsPerPage],
    () => {
      const params = {
        page: page + 1,
        per_page: rowsPerPage,
      };
      return getListUser(params);
    },
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (data) {
      setArrUsers(transformUserData(data.data.data));
      setTotal(data.data.total);
    }
  }, [data]);

  useEffect(() => {
    setSortedData(arrUsers.sort((a, b) => b.id - a.id));
  }, [arrUsers]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {

  }, [editUser]);

  const transformUserData = (data) => {
    return data.map((user) => {
      return {
        id: user.id.toString(),
        name: user.name,
        email: user.email,
        password: "****",
        role: user.role,
        remarks: user.remarks || "",
      };
    });
  };

  const handleSort = (columnIndex) => {
    const isSorted = activeSortColumn === columnIndex;
    let direction = isSorted
      ? sortColumnDirection === "asc"
        ? "desc"
        : "asc"
      : "desc";
    sortedData.sort((a, b) => {
      let aValue = a[Object.keys(a)[columnIndex]];
      let bValue = b[Object.keys(b)[columnIndex]];

      // if aValue and bValue are numberic, sort parseInt
      if (isNumeric(aValue) && isNumeric(bValue)) {
        aValue = parseInt(aValue);
        bValue = parseInt(bValue);
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData([...sortedData]);
    setActiveSortColumn(columnIndex);
    setSortColumnDirection(direction);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleAddUser = async () => {
    try {
      const newUserData = {
        name: newUser.name,
        email: newUser.email,
        password: newUser.password,
        role: newUser.role,
        remarks: newUser.remarks,
      };
      const response = await createUser(newUserData);

      if (response && response.data) {
        refetch();
        setNewUser({ name: "",email: "", password: "",role: "", remarks: "" });
        setErrors({ name: "", email: "", password: "", role: "", remarks: "" });
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrors((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      } else if (error.response && error.response.status === 403) {
        alert("権限がありません。");
      } else {
        alert("エラーが発生しました。");
      }
    }
  };

  const handleUpdateUser = async (updatedUser) => {
    try {
      const { id, name, email, password, role, remarks } = updatedUser;
      const updatedData = {
        name: name,
        email: email,
        password: password,
        remarks: remarks,
        role: role
      };

      const response = await updateUser(id, updatedData);
      if (response && response.data) {
        refetch();
        return true;
      } else {
        alert("エラーが発生しました。");
        return false
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const fieldErrors = error.response.data.message;
        setErrorsUpdate((prev) => ({
          ...prev,
          ...fieldErrors,
        }));
      }
      else {
        alert("エラーが発生しました。");
      }
      return false;
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      const response = await deleteUser(id);

      if (response.success) {
        refetch();
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    const maxPage = Math.floor(total / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(Math.min(page, maxPage));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFocus = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };

  const handleBlur = (field) => {
    setFocusState((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };

  const handleClosePopup = () => {
    setIsPopupOpenUpdate(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorsUpdate((prev) => ({ ...prev, [name]: "" }));
  };

  const handleEditClick = (user) => {
    const getFeeId = (feeValue) => {
      const option = roleOptions.find((o) => o.id === feeValue);
      return option ? option.id : "";
    };

    const roleId = getFeeId(user.role);
    setEditUser({
      ...user,
      role: roleId,
      password: ''
    });
    setIsPopupOpenUpdate(true);
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setIsPopupOpenDelete(true);
  };

  return {
    arrUsers,
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newUser,
    rowsPerPage,
    page,
    total,
    editUser,
    isPopupOpenDelete,
    isPopupOpenUpdate,
    userToDelete,
    focusState,
    nameInputRef,
    roleOptions,
    errors,
    errorsUpdate,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddUser,
    handleUpdateUser,
    handleDeleteUser,
    handleFocus,
    handleBlur,
    handleClosePopup,
    handleChange,
    handleEditClick,
    handleDeleteClick,
    setIsPopupOpenDelete,
  };
};

export default useUserMaster;
