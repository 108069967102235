import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Button,
  styled,
  MenuItem,
  Select,
  FormControl,
  FormLabel,
  InputBase,
  FormHelperText,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import { useCallback } from "react";
import TablePaginationLayout from "../../layouts/TablePagination";
import useTherapistMaster from "../../features/therapist";
import PopupComponent from "../../layouts/Popup";
import DeleteIcon from "@mui/icons-material/Delete";
import { MAIN_NOMINATION_COUNT_FORMULA } from "../../constants";
import PopupTherapistNomination from "../../components/PopupTherapistNomination";
import ImageUploader from "../../components/ImageUploader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const columnTitles = [
  "id",
  "日時",
  "時間",
  "名前",
  "パスワード",
  "紹介者",
  "メモ",
  "操作",
];

const CustomButtonEdit = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const CustomButtonDelete = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  color: theme.palette.error.main,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: theme.palette.error.light,
    borderColor: theme.palette.error.main,
  },
}));

const CustomSaveButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: "transparent",
  width: "100%",
  "&:hover": {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const TherapistMaster = () => {
  const {
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newTherapist,
    rowsPerPage,
    total,
    page,
    editTherapist,
    isPopupOpenDelete,
    isPopupOpenUpdate,
    isPopupManageNominationOpen,
    therapistToDelete,
    focusState,
    nameInputRef,
    arrIntroducers,
    errors,
    errorsUpdate,
    newAvatarSrc,
    newAvatarError,
    editAvatarSrc,
    editAvatarError,
    setNewAvatarFile,
    setNewAvatarSrc,
    setNewAvatarError,
    setEditAvatarFile,
    setEditAvatarSrc,
    setEditAvatarError,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddTherapist,
    handleUpdateTherapist,
    handleDeleteTherapist,
    handleFocus,
    handleBlur,
    handleClosePopup,
    handleChange,
    handleEditClick,
    handleDeleteClick,
    setIsPopupOpenDelete,
    setIsPopupOpenUpdate,
    setIsPopupManageNominationOpen,
  } = useTherapistMaster();

  const checkKeyHiddenInTable = (key) => {
    return key === "default_main_nomination_fee" || key === "avatar" || key === "training_date";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const handleSuccess = await handleUpdateTherapist(editTherapist);
      if (handleSuccess) {
        handleClosePopup();
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleSubmitDelete = useCallback(async () => {
    await handleDeleteTherapist(therapistToDelete.id);
    setIsPopupOpenDelete(false);
  }, [therapistToDelete, handleDeleteTherapist, setIsPopupOpenDelete]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <HeaderReservationManagement />
      <Navbar />
      {isPopupOpenUpdate && (
        <PopupComponent open={isPopupOpenUpdate} onClose={handleClosePopup} classNotClose="MuiPickersPopper-root">
          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.name}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="name"
              id="name-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.name || editTherapist.name
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
              セラピスト名 *
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="name"
              name="name"
              type="text"
              onFocus={() => handleFocus("name")}
              onBlur={() => handleBlur("name")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editTherapist.name}
              onChange={handleChange}
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                {
                  borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transition:
                    "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                {
                  borderBottom: "2px solid rgb(0, 0, 0)",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                {
                  borderBottom: "2px solid rgb(25, 118, 210)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transform: "translateX(0px) scaleX(1)",
                  transition:
                    "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
              }}
              inputRef={nameInputRef}
            />
            <FormHelperText>{errorsUpdate.name}</FormHelperText>
          </FormControl>

          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.base_password}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="base_password"
              id="base_password-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.base_password || editTherapist.base_password
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
              パスワード *
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="base_password"
              name="base_password"
              type="text"
              onFocus={() => handleFocus("base_password")}
              onBlur={() => handleBlur("base_password")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editTherapist.base_password}
              onChange={handleChange}
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                {
                  borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transition:
                    "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                {
                  borderBottom: "2px solid rgb(0, 0, 0)",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                {
                  borderBottom: "2px solid rgb(25, 118, 210)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transform: "translateX(0px) scaleX(1)",
                  transition:
                    "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
              }}
            />
            <FormHelperText>{errorsUpdate.base_password}</FormHelperText>
          </FormControl>

          <Grid item xs={12}>
            <ImageUploader
              setImageFile={setEditAvatarFile}
              imageSrc={editAvatarSrc}
              setImageSrc={setEditAvatarSrc}
              imageError={editAvatarError}
              setImageError={setEditAvatarError}
              btnUploadText="写真をアップロード"
              btnChangeText="アバターを変更"
              maxWidthContainer="400px"
              maxWidthImage="300px"
            />
          </Grid>

          <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
            <span>指名料の編集</span>
            <Button
              type="button"
              variant="outlined"
              color="success"
              style={{ marginLeft: 10 }}
              onClick={() => {
                setIsPopupOpenUpdate(false)
                setIsPopupManageNominationOpen(true)
              }}
            >
              編集
            </Button>
          </div>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              marginTop: "25px",
            }}
          >
            <DatePicker
              label="研修日"
              value={editTherapist.training_date || null}
              onChange={(date) => handleChange({ target: { name: "training_date", value: date } })}
              renderInput={(params) => (
                <TextField {...params} fullWidth />
              )}
            />
            <FormHelperText sx={{ color: "red" }}>
              {errorsUpdate?.training_date}
            </FormHelperText>
          </Box>

          <Select
            fullWidth
            name="introducer_id"
            sx={{ marginTop: "25px" }}
            value={editTherapist.introducer_id}
            onChange={(event) => {
              handleChange(event);
            }}
            displayEmpty
            required
            MenuProps={{
              disablePortal: true,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              PaperProps: {
                style: {
                  transform: "translateY(50px) translateX(40px)",
                },
              },
            }}
          >
            <MenuItem value="">
              <em>選択してください</em>
            </MenuItem>
            {arrIntroducers.map((option, index) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>

          <FormControl
            sx={{ width: "100%" }}
            error={!!errorsUpdate.remarks}
          >
            <textarea
              aria-label="minimum height"
              id="edit_remarks"
              name="remarks"
              placeholder="メモ"
              style={{
                width: "100%",
                height: "60px",
                marginTop: 30,
                borderRadius: 5,
                fontSize: 18,
                backgroundColor: "transparent",
                outline: "none",
              }}
              value={editTherapist.remarks}
              onChange={handleChange}
            />
            <FormHelperText>{errorsUpdate.remarks}</FormHelperText>
          </FormControl>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            color="success"
            style={{ marginTop: 20 }}
            onClick={handleSubmit}
          >
            保存
          </Button>
        </PopupComponent>
      )}

      <PopupTherapistNomination
        therapist={editTherapist}
        isOpen={isPopupManageNominationOpen}
        onClose={() => setIsPopupManageNominationOpen(false)}
        onSubmit={() => setIsPopupOpenUpdate(true)}
      />

      {isPopupOpenDelete && (
        <PopupComponent
          open={isPopupOpenDelete}
          onClose={() => setIsPopupOpenDelete()}
        >
          <Typography variant="h4" gutterBottom>
            確認
          </Typography>
          <Typography variant="body1" paragraph>
            削除するデータを選択しています。続行しますか?
          </Typography>

          <Button
            type="button"
            fullWidth
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="error"
            style={{ marginTop: "20px" }}
            onClick={handleSubmitDelete}
          >
            消す
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setIsPopupOpenDelete(false)}
            fullWidth
            style={{ marginTop: "20px" }}
          >
            キャンセル
          </Button>
        </PopupComponent>
      )}
      <Container maxWidth="lg">
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0px 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          セラピストマスター
        </Typography>
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【新規登録】
          </Typography>
          <Paper
            sx={{
              padding: "16px",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="セラピスト名"
                  name="name"
                  value={newTherapist.name}
                  onChange={handleInputChange}
                  required
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="パスワード"
                  name="base_password"
                  value={newTherapist.base_password}
                  onChange={handleInputChange}
                  required
                  error={!!errors.base_password}
                  helperText={errors.base_password}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageUploader
                  setImageFile={setNewAvatarFile}
                  imageSrc={newAvatarSrc}
                  setImageSrc={setNewAvatarSrc}
                  imageError={newAvatarError}
                  setImageError={setNewAvatarError}
                  btnUploadText="写真をアップロード"
                  btnChangeText="アバターを変更"
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <DatePicker
                    label="研修日"
                    value={newTherapist.training_date || null}
                    onChange={(date) => handleInputChange({ target: { name: "training_date", value: date } })}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {errors?.training_date}
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  name="introducer_id"
                  value={newTherapist.introducer_id}
                  onChange={handleInputChange}
                  displayEmpty
                  required
                >
                  <MenuItem value="">
                    <em>紹介者を選択してください</em>
                  </MenuItem>
                  {arrIntroducers.map((option, index) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="メモ"
                  name="remarks"
                  value={newTherapist.remarks}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  error={!!errors.remarks}
                  helperText={errors.remarks}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <CustomSaveButton
                  variant="outlined"
                  onClick={handleAddTherapist}
                >
                  保存
                </CustomSaveButton>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【登録済み一覧】
          </Typography>
          <Paper
            sx={{
              marginTop: "16px",
              backgroundColor: "#fff",
              boxShadow: "none",
            }}
          >
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {columnTitles.map((title, index) => {
                      const isSorted = activeSortColumn === index;
                      const sortDirection = isSorted
                        ? sortColumnDirection
                        : "asc";

                      return (
                        <TableCell
                          key={index}
                          align="center"
                          sx={{ padding: "8px 16px", color: "#000" }}
                          sortDirection={isSorted ? sortDirection : false}
                        >
                          <TableSortLabel
                            active={isSorted}
                            direction={sortDirection}
                            onClick={() => handleSort(index)}
                          >
                            {title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, rowIndex) => (
                    <TableRow key={rowIndex} className="MuiTableRow-hover">
                      {Object.entries(row).map(([key, value], cellIndex) => !checkKeyHiddenInTable(key) && (
                        <TableCell
                          key={cellIndex}
                          align="center"
                          sx={{ color: "#000" }}
                        >
                          {key === "main_nomination_fee"
                            ? value === null
                              ? MAIN_NOMINATION_COUNT_FORMULA
                              : value
                            : value}
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        <CustomButtonEdit
                          variant="outlined"
                          onClick={() => handleEditClick(row)}
                        >
                          編集
                        </CustomButtonEdit>
                        <CustomButtonDelete
                          variant="outlined"
                          sx={{ marginLeft: 1 }}
                          onClick={() => handleDeleteClick(row)}
                        >
                          削除
                        </CustomButtonDelete>
                      </TableCell>
                    </TableRow>
                  ))}
                  {sortedData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columnTitles.length} align="center">
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={rowsPerPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={handlePageChange}
              handleChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Container>
    </LocalizationProvider >
  );
};

export default TherapistMaster;
