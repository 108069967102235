import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import FormFieldSelect from "../../components/form/FormFieldSelect";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import ModalSearchCustomer from "./ModalSearchCustomer";
import { createOrder, deleteOrder, updateOrder } from "../../api/orders";
import { getCost, getDuration, handleSetErrorToField } from "../../utils";
import {
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_CARD_CHARGE,
  PAYMENT_METHOD_CARD_FEE,
  PAYMENT_METHOD_CASH,
} from "../../constants";
import { useQuery } from "react-query";
import { getListTherapistNominations } from "../../api/therapistNomination";
import { addCalendarRestTime } from "../../api/therapist";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxWidth: "calc(100% - 40px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  maxHeight: "100dvh",
  overflowY: "auto",
  paddingBottom: "16px",
};

const ModalReservationRegister = ({
  open,
  handleClose,
  item,
  courseOptions,
  extensionOptions,
  paymentMethodOptions,
  optionOptions,
  refetch,
}) => {
  const [isOpenSubModal, setIsOpenSubModal] = useState(false);
  const [typeModal, setTypeModal] = useState("name");
  const [nomitationOptions, setNomitationOptions] = useState([]);

  const [flag, setFlag] = useState(false);

  const { data: therapistNominationsData } = useQuery(
    ["therapistNominations", item.therapist_id],
    () => {
      return getListTherapistNominations(item.therapist_id);
    },
    {
      enabled: !!item.therapist_id,
    }
  );

  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const courseId = useWatch({ control, name: "course_id", defaultValue: "" });
  const coursePaymentMethodId = useWatch({
    control,
    name: "course_payment_method_id",
    defaultValue: PAYMENT_METHOD_CASH,
  });
  const extensionId = useWatch({
    control,
    name: "extension_id",
    defaultValue: "",
  });
  const extensionPaymentMethodId = useWatch({
    control,
    name: "extension_payment_method_id",
    defaultValue: PAYMENT_METHOD_CASH,
  });
  const optionId = useWatch({ control, name: "option_id", defaultValue: "" });
  const optionPaymentMethodId = useWatch({
    control,
    name: "option_payment_method_id",
    defaultValue: PAYMENT_METHOD_CASH,
  });
  const nomitationId = useWatch({
    control,
    name: "nomination_id",
    defaultValue: "",
  });
  const discount = useWatch({ control, name: "discount", defaultValue: 0 });
  const dateStart = useWatch({
    control,
    name: "date_start",
    defaultValue: undefined,
  });
  const restTime = useWatch({
    control,
    name: "rest_time",
    defaultValue: 20,
  });

  useEffect(() => {
    if (therapistNominationsData) {
      const therapistNominations = therapistNominationsData.data;
      const nominations = therapistNominations.map((item) => ({
        id: item.nomination_id,
        name: item.name,
        cost: item.nomination_cost,
      }));

      setNomitationOptions(nominations);
    }
  }, [therapistNominationsData]);

  useEffect(() => {
    if (flag === false) {
      setFlag(true);
      return;
    }
    let totalCost = 0;

    let cousreCost = 0;
    if (courseId) cousreCost = getCost(courseOptions, courseId);
    if (coursePaymentMethodId === PAYMENT_METHOD_CARD) {
      cousreCost =
        cousreCost * PAYMENT_METHOD_CARD_CHARGE + PAYMENT_METHOD_CARD_FEE;
    }

    let nomitationFee = 0;
    if (nomitationId) nomitationFee = getCost(nomitationOptions, nomitationId);
    if (coursePaymentMethodId === PAYMENT_METHOD_CARD) {
      nomitationFee =
        nomitationFee * PAYMENT_METHOD_CARD_CHARGE + PAYMENT_METHOD_CARD_FEE;
    }

    let extensionCost = 0;
    if (extensionId) extensionCost = getCost(extensionOptions, extensionId);
    if (extensionPaymentMethodId === PAYMENT_METHOD_CARD) {
      extensionCost =
        extensionCost * PAYMENT_METHOD_CARD_CHARGE + PAYMENT_METHOD_CARD_FEE;
    }

    let optionCost = 0;
    if (optionId) optionCost = getCost(optionOptions, optionId);
    if (optionPaymentMethodId === PAYMENT_METHOD_CARD) {
      optionCost =
        optionCost * PAYMENT_METHOD_CARD_CHARGE + PAYMENT_METHOD_CARD_FEE;
    }

    const discountPrice = Number(discount) || 0;
    totalCost =
      cousreCost + nomitationFee + extensionCost + optionCost - discountPrice;
    totalCost = Math.round(totalCost);
    setValue("total_cost", Math.max(totalCost, 0));

    if (dateStart && courseId) {
      let totalDuration = Number(restTime) || 0;
      if (courseId)
        totalDuration += getDuration(courseOptions, courseId, "duration");
      if (extensionId)
        totalDuration += getDuration(
          extensionOptions,
          extensionId,
          "extra_time"
        );

      const newDateEnd = dateStart.add(totalDuration, "minute");
      setValue("date_end", newDateEnd);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    courseId,
    extensionId,
    optionId,
    nomitationId,
    coursePaymentMethodId,
    extensionPaymentMethodId,
    optionPaymentMethodId,
    discount,
    dateStart,
    restTime,
  ]);

  useEffect(() => {
    setFlag(false);
    reset({
      therapist_id: item.therapist_id,
      store_id: item.store_id,
      therapist_name: item.therapist_name,
      nomination_id: item.nomination_id || "",
      customer_id: item.customer_id || "",
      customer_name: item.customer_name || "",
      customer_tel: item.customer_tel || "",
      discount: item.discount || 0,
      total_cost: parseInt(item.total_cost) || 0,
      payment_fee: item.payment_fee || 0,
      course_id: item.course_id || "",
      course_payment_method_id:
        item.course_payment_method_id || PAYMENT_METHOD_CASH,
      extension_id: item.extension_id || "",
      extension_payment_method_id:
        item.extension_payment_method_id || PAYMENT_METHOD_CASH,
      option_id: item.option_id || "",
      option_payment_method_id:
        item.option_payment_method_id || PAYMENT_METHOD_CASH,
      customer_remarks: item.customer_remarks,
      therapist_remarks: item.therapist_remarks,
      contact_remarks: item.contact_remarks,
      is_first: item.is_first || 0,
      date: item.date,
      status: item.status || 0,
      date_start: item.date_start
        ? dayjs(item.date_start, "YYYY-MM-DD HH:mm:ss")
        : undefined,
      date_end: item.date_end
        ? dayjs(item.date_end, "YYYY-MM-DD HH:mm:ss")
        : undefined,
      rest_time: item.rest_time || 20,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleDeleteCalendar = async () => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (item.id && confirm("この予約をキャンセルしますか？")) {
        await deleteOrder(item.id);
        handleClose();
        refetch();
        alert("予約をキャンセルしました。");
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleAddRestTime = async () => {
    const values = getValues();
    const payload = {
      time_start: values.date_start.format("YYYY-MM-DD HH:mm:ss"),
      time_end: values.date_end.format("YYYY-MM-DD HH:mm:ss"),
    };

    try {
      await addCalendarRestTime(item.calendar_id, payload);
      refetch();
      handleClose();
      alert("対応不可時間を追加しました。");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            for (let key in messages) {
              if (key === "time_start") {
                setError("date_start", {
                  type: "manual",
                  message: messages[key],
                });
              }
              if (key === "time_end") {
                setError("date_end", {
                  type: "manual",
                  message: messages[key],
                });
              }
            }
          }
        } else {
          alert("エラーが発生しました。");
        }
      } else {
        alert("エラーが発生しました。");
      }
    }
  };

  const onSubmit = async (data) => {
    const extensions = [];
    const options = [];

    if (data.extension_id && data.extension_payment_method_id) {
      extensions.push({
        id: data.extension_id,
        payment_method_id: data.extension_payment_method_id,
      });
    }

    if (data.option_id && data.option_payment_method_id) {
      options.push({
        id: data.option_id,
        payment_method_id: data.option_payment_method_id,
      });
    }

    const payload = {
      therapist_id: data.therapist_id,
      store_id: data.store_id,
      nomination_id: data.nomination_id,
      course_id: data.course_id,
      date_start: data.date_start
        ? dayjs(data.date_start).format("YYYY-MM-DD HH:mm:ss")
        : null,
      date_end: data.date_end
        ? dayjs(data.date_end).format("YYYY-MM-DD HH:mm:ss")
        : null,
      rest_time: data.rest_time,
      date: item.date,
      status: data.status,
      customer_id: data.customer_id,
      customer_name: data.customer_name,
      customer_tel: data.customer_tel,
      is_first: data.is_first,
      customer_remarks: data.customer_remarks,
      therapist_remarks: data.therapist_remarks,
      contact_remarks: data.contact_remarks,
      discount: data.discount,
      total_cost: data.total_cost,
      payment_fee: data.payment_fee,
      course_payment_method_id: data.course_payment_method_id,
      extensions,
      options,
    };

    try {
      if (item.id) {
        await updateOrder(item.id, payload);
      } else {
        await createOrder(payload);
      }

      refetch();
      handleClose();
      alert("予約が完了しました。");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            if (typeof messages === "object") {
              handleSetErrorToField(messages, setError);
              return;
            }
          }
        } else if (error.response.status === 406) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            alert(messages || "エラーが発生しました。");
          }
        } else {
          alert("エラーが発生しました。");
        }
      } else {
        alert("エラーが発生しました。");
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        z
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              backgroundColor: "rgb(136, 160, 185)",
              color: "white",
              padding: "16px",
              boxShadow:
                " rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px",
            }}
          >
            予約登録
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
                alignItems="flex-startz"
                sx={{ padding: "32px" }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                  <Controller
                    name="therapist_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="セラピスト"
                        variant="standard"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        disabled={true}
                      />
                    )}
                  />
                  <FormFieldSelect
                    control={control}
                    errors={errors}
                    register={register}
                    options={nomitationOptions}
                    name="nomination_id"
                    label="指名"
                  />
                  <Controller
                    name="discount"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="割引"
                        variant="standard"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="body2">円</Typography>
                            </InputAdornment>
                          ),
                        }}
                        type="number"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (!/^\d*$/.test(value)) {
                            e.target.value = value.replace(/[^\d]/g, "");
                          }
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  <Box sx={{ display: "flex", gap: "16px" }}>
                    <FormFieldSelect
                      control={control}
                      errors={errors}
                      register={register}
                      options={courseOptions}
                      name="course_id"
                      label="メニュー"
                    />
                    <FormFieldSelect
                      control={control}
                      errors={errors}
                      register={register}
                      options={paymentMethodOptions}
                      name="course_payment_method_id"
                      label="支払方法"
                    />
                  </Box>

                  <Box sx={{ display: "flex", gap: "16px" }}>
                    <FormFieldSelect
                      control={control}
                      errors={errors}
                      register={register}
                      options={extensionOptions}
                      name="extension_id"
                      label="延長"
                    />
                    <FormFieldSelect
                      control={control}
                      errors={errors}
                      register={register}
                      options={paymentMethodOptions}
                      name="extension_payment_method_id"
                      label="支払方法"
                    />
                  </Box>

                  <Box sx={{ display: "flex", gap: "16px" }}>
                    <FormFieldSelect
                      control={control}
                      errors={errors}
                      register={register}
                      options={optionOptions}
                      name="option_id"
                      label="オプション"
                    />
                    <FormFieldSelect
                      control={control}
                      errors={errors}
                      register={register}
                      options={paymentMethodOptions}
                      name="option_payment_method_id"
                      label="支払方法"
                    />
                  </Box>

                  <Controller
                    name="payment_fee"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="カード手数料"
                        variant="standard"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        disabled={true}
                      />
                    )}
                  />

                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Controller
                        name="date_start"
                        control={control}
                        render={({ field }) => (
                          <DateTimePicker
                            label="開始予約時間"
                            value={field.value}
                            onChange={(date) => field.onChange(date)}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        )}
                      />
                      <FormHelperText sx={{ color: "red" }}>
                        {errors?.date_start?.message}
                      </FormHelperText>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Controller
                        name="date_end"
                        control={control}
                        render={({ field }) => (
                          <DateTimePicker
                            label="終了予約時間"
                            value={field.value}
                            onChange={(date) => field.onChange(date)}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        )}
                      />
                      <FormHelperText sx={{ color: "red" }}>
                        {errors?.date_end?.message}
                      </FormHelperText>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Controller
                        name="rest_time"
                        control={control}
                        defaultValue="20"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="休憩時間"
                            variant="standard"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography variant="body2">分</Typography>
                                </InputAdornment>
                              ),
                            }}
                            type="number"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (!/^\d*$/.test(value)) {
                                e.target.value = value.replace(/[^\d]/g, "");
                              }
                              field.onChange(e);
                            }}
                            error={!!errors.rest_time}
                            helperText={
                              errors.rest_time ? errors.rest_time.message : ""
                            }
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                  <Controller
                    name="total_cost"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="料金"
                        variant="standard"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="body2">円</Typography>
                            </InputAdornment>
                          ),
                        }}
                        type="number"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (!/^\d*$/.test(value)) {
                            e.target.value = value.replace(/[^\d]/g, "");
                          }
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  <Box sx={{ display: "flex", gap: "4px" }}>
                    <Controller
                      name="customer_name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="お客様の名前"
                          variant="standard"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={!!errors.customer_name}
                          helperText={
                            errors.customer_name
                              ? errors.customer_name.message
                              : ""
                          }
                        />
                      )}
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        fontSize: "12px",
                        padding: 0,
                      }}
                      onClick={() => {
                        setTypeModal("name");
                        setIsOpenSubModal(true);
                      }}
                    >
                      顧客検索
                    </Button>
                  </Box>

                  <Box sx={{ display: "flex", gap: "4px" }}>
                    <Controller
                      name="customer_tel"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="お客様　TEL "
                          variant="standard"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={!!errors.customer_tel}
                          helperText={
                            errors.customer_tel
                              ? errors.customer_tel.message
                              : ""
                          }
                        />
                      )}
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        fontSize: "12px",
                        padding: 0,
                      }}
                      onClick={() => {
                        setTypeModal("tel");
                        setIsOpenSubModal(true);
                      }}
                    >
                      顧客検索
                    </Button>
                  </Box>

                  <FormFieldSelect
                    control={control}
                    errors={errors}
                    register={register}
                    options={[
                      { id: 0, name: "新規" },
                      { id: 1, name: "リピーター" },
                    ]}
                    name="is_first"
                    label="新規/リピータ"
                  />

                  <Controller
                    name="customer_remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        multiline
                        rows={2}
                        variant="outlined"
                        label="お客様 要望"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <Controller
                    name="therapist_remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        multiline
                        rows={2}
                        variant="outlined"
                        label="セラピスト要望"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <Controller
                    name="contact_remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        multiline
                        rows={2}
                        variant="outlined"
                        label="連絡用備考"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <FormFieldSelect
                    control={control}
                    errors={errors}
                    register={register}
                    options={[
                      { id: 0, name: "なし" },
                      { id: 1, name: "あり" },
                    ]}
                    isHasDefaultOption={false}
                    name="status"
                    label="領収書発行の有無"
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  padding: "0 32px 20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  type="button"
                  onClick={handleAddRestTime}
                  variant="contained"
                  color="error"
                >
                  対応不可
                </Button>
                <Box
                  sx={{
                    padding: "0",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    columnGap: "16px",
                  }}
                >
                  <Button variant="outlined" type="submit">
                    この内容で保存する
                  </Button>
                  {item.id && (
                    <Button
                      variant="outlined"
                      type="button"
                      color="error"
                      onClick={handleDeleteCalendar}
                    >
                      予約をキャンセル
                    </Button>
                  )}
                </Box>
              </Box>
            </form>
          </LocalizationProvider>
        </Box>
      </Modal>

      <ModalSearchCustomer
        open={isOpenSubModal}
        setOpen={setIsOpenSubModal}
        setValue={setValue}
        typeModal={typeModal}
      />
    </>
  );
};

export default ModalReservationRegister;
